import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import { TooltipWrapper } from 'components/Tooltip/Tooltip'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { isBlockArticleType } from 'common/utils/isBlockArticleType'
import { PROJECT_STATUSES } from 'common/constants/projects'
import { isAppSumoUser, isAppSumoUserTear, isEducation, isProPlusOrBetter } from 'common/utils/permissions'

import { getFullUrlToSsr } from 'utils/router'

import {
    EditPencilIcon,
    GameControlIcon,
    IconCrown,
    OpenInNewWindow,
    SettingsIcon,
    ShareArrowIcon,
    StatsIcon,
} from 'svg'

import { MODAL_TYPES } from '../../../../modals/ModalController'

import './ActionButtons.scss'

const ActionButtons = ({
    isMultiplayer,
    isUserAuthor,
    project,
    isUserOwnerAndAuthor,
    isSubscriptionExpired,
    onOpenModal,
    onEditProject,
    onOpenStartGameModal,
    onOpenShareModal,
    isLoading,
    dropdownMenuItems,
}) => {
    const { t } = useTranslation()

    const isMin1600 = useMediaQuery({ minWidth: 1600 })
    const isMax1340 = useMediaQuery({ maxWidth: 1340 })
    const isMax1400 = useMediaQuery({ maxWidth: 1400 })

    const isHiddenTooltips = isMin1600 || isMax1340

    const isPublished = project.projectStatus === PROJECT_STATUSES.PUBLISHED
    const isTemplate = project.projectStatus === PROJECT_STATUSES.TEMPLATE

    const isCanUpgrade =
        !isTemplate &&
        isBlockArticleType(project.projectType.projectType) &&
        isUserOwnerAndAuthor &&
        (!project.projectSubscription || project.projectSubscription.expired) &&
        (!isAppSumoUser() || isAppSumoUserTear(['1', '2'])) &&
        (!isEducation() || isAppSumoUser()) &&
        !isMultiplayer &&
        !isProPlusOrBetter()

    return (
        <ul className="project-block__buttons-view">
            {isCanUpgrade && (
                <li>
                    <TooltipWrapper tooltipText={t('Upgrade')} isHidden={isHiddenTooltips}>
                        <button
                            className={classNames('button', 'project-block__buttons-view_upgrade-button', {
                                'is-hidden-text': isMax1340,
                                'is-loading': isLoading,
                            })}
                            data-size="md"
                            data-variant="secondary"
                            onClick={evt => onOpenModal(MODAL_TYPES.upgradeProjectTariff, { project }, evt)}
                        >
                            <span className="project-block__buttons-view-icon">
                                <IconCrown isLoading={isLoading} color="#785cc7" />
                            </span>
                            {t('Upgrade')}
                        </button>
                    </TooltipWrapper>
                </li>
            )}
            {isPublished && (
                <>
                    {!isMultiplayer && (
                        <li>
                            <TooltipWrapper tooltipText={t('Share / embed')} isHidden={isHiddenTooltips}>
                                <button
                                    className={classNames('button', {
                                        'is-hidden-text': isMax1400,
                                        'is-loading': isLoading,
                                    })}
                                    data-size="md"
                                    data-variant="secondary"
                                    onClick={evt => onOpenShareModal(project, evt)}
                                >
                                    <span className="project-block__buttons-view-icon">
                                        <ShareArrowIcon isLoading={isLoading} />
                                    </span>
                                    {t('Share / embed')}
                                </button>
                            </TooltipWrapper>
                        </li>
                    )}
                    {isMultiplayer && isUserAuthor && (
                        <li>
                            <TooltipWrapper tooltipText={t('Start / share')} isHidden={isHiddenTooltips}>
                                <button
                                    className={classNames('button', {
                                        'is-hidden-text': isMax1340,
                                        'is-loading': isLoading,
                                    })}
                                    data-size="md"
                                    data-variant="secondary"
                                    onClick={evt => onOpenStartGameModal(project, evt)}
                                >
                                    <span className="project-block__buttons-view-icon">
                                        <GameControlIcon isLoading={isLoading} />
                                    </span>
                                    {t('Start / share')}
                                </button>
                            </TooltipWrapper>
                        </li>
                    )}
                    {!isMultiplayer && isBlockArticleType(project.projectType.projectType) && (
                        <li>
                            <TooltipWrapper tooltipText={t('Statistics / leads')} isHidden={isHiddenTooltips}>
                                <Link
                                    to={isSubscriptionExpired ? '#' : `/my-projects/${project.projectId}/analytics`}
                                    className={classNames('button', 'is-hidden-text', { 'is-loading': isLoading })}
                                    data-size="md"
                                    data-variant="secondary"
                                    data-test="view-detail-stats"
                                    onClick={
                                        isSubscriptionExpired
                                            ? evt => onOpenModal(MODAL_TYPES.subscriptionExpired, project, evt)
                                            : null
                                    }
                                >
                                    <span className="project-block__buttons-view-icon">
                                        <StatsIcon isLoading={isLoading} />
                                    </span>
                                    {t('Statistics / leads')}
                                </Link>
                            </TooltipWrapper>
                        </li>
                    )}
                </>
            )}
            {isTemplate && (
                <li>
                    <TooltipWrapper tooltipText={t('View')} isHidden={isHiddenTooltips}>
                        <button
                            className={classNames('button', {
                                'is-hidden-text': isMax1400,
                                'is-loading': isLoading,
                            })}
                            data-size="md"
                            data-variant="secondary"
                            onClick={() =>
                                window.open(
                                    getFullUrlToSsr(`/author-templates/${project.templatePublicContentId}`, '_blank'),
                                )
                            }
                        >
                            <span className="project-block__buttons-view-icon">
                                <OpenInNewWindow isLoading={isLoading} />
                            </span>
                            {t('View')}
                        </button>
                    </TooltipWrapper>
                </li>
            )}
            {isBlockArticleType(project.projectType.projectType) && (
                <li>
                    {!isTemplate ? (
                        <button
                            className={classNames('button', {
                                'is-hidden-text': isPublished,
                                'is-loading': isLoading,
                            })}
                            data-size="md"
                            data-variant="secondary"
                            data-test="edit-project"
                            onClick={evt => onEditProject(project, evt)}
                        >
                            <span className="project-block__buttons-view-icon">
                                <EditPencilIcon isLoading={isLoading} />
                            </span>
                            {t('Edit')}
                        </button>
                    ) : (
                        <button
                            className={classNames('button', 'is-hidden-text', { 'is-loading': isLoading })}
                            data-size="md"
                            data-variant="secondary"
                            data-test="edit-project"
                            onClick={evt => onOpenModal(MODAL_TYPES.editTemplateModal, { project }, evt)}
                        >
                            <span className="project-block__buttons-view-icon">
                                <EditPencilIcon isLoading={isLoading} />
                            </span>
                            {t('Edit')}
                        </button>
                    )}
                </li>
            )}
            {!!dropdownMenuItems.length && (
                <li>
                    <DropdownMenu
                        className="us-n"
                        placement={'topRight'}
                        actionHtml={
                            <TooltipWrapper tooltipText={t('Settings menu')}>
                                <button
                                    className={classNames('button', 'settings-button', { 'is-loading': isLoading })}
                                    data-size="md"
                                    data-variant="secondary"
                                >
                                    <SettingsIcon isLoading={isLoading} />
                                </button>
                            </TooltipWrapper>
                        }
                        items={dropdownMenuItems}
                    />
                </li>
            )}
        </ul>
    )
}

export default ActionButtons
